import { useEffect } from 'react'
import { useRouter } from 'next/router'

const Redirect = ({ to, ssr = false }: RedirectProps): null => {
  const { replace } = useRouter()

  useEffect(() => {
    if (ssr) {
      // eslint-disable-next-line xss/no-location-href-assign
      window.location.href = to
    } else {
      replace(to)
    }
  }, [replace, to, ssr])

  return null
}

type RedirectProps = {
  to: string
  ssr?: boolean
}

export default Redirect
