import { forwardRef, MouseEventHandler, PropsWithChildren } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { UrlObject } from 'url'
import { HeightProps, SpaceProps } from 'styled-system'

const withLinkWrapper = (WrappedComponent) => {
  const Component = forwardRef<HTMLAnchorElement, PropsWithChildren<LinkProps>>(
    ({ as, useSSR, scroll, href, replace, ...props }, ref) => {
      if (useSSR || isServerLink(href)) {
        return <WrappedComponent ref={ref} href={href} {...props} />
      }

      return (
        <Link
          legacyBehavior
          href={href}
          as={as}
          passHref={!!as}
          scroll={scroll}
          replace={replace}
        >
          <WrappedComponent ref={ref} href={href} {...props} />
        </Link>
      )
    }
  )

  Component.propTypes = {
    href: hrefAsPropTypes,
    as: hrefAsPropTypes, // only used for dynamic links
    useSSR: PropTypes.bool,
    scroll: PropTypes.bool,
  }

  return Component
}

interface LinkProps {
  as?: string | UrlObject
  href?: string | UrlObject
  useSSR?: boolean
  scroll?: boolean
  noStyle?: boolean
  underline?: boolean
  maxLines?: number
  replace?: boolean
  variant?: string
  color?: string
  px?: SpaceProps['px']
  pr?: SpaceProps['pr']
  mr?: SpaceProps['mr']
  ml?: SpaceProps['ml']
  mt?: SpaceProps['mt']
  fontWeight?: string
  height?: HeightProps['height']
  target?: string
  display?: string
  onClick?: MouseEventHandler
  hoverColor?: string
}

const hrefAsPropTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    query: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }),
])

const linkPropTypes = PropTypes.shape({
  href: hrefAsPropTypes.isRequired,
  // As is only required for dynamic links
  as: hrefAsPropTypes,
})

const isServerLink = (href) =>
  !href ||
  (typeof href === 'string' &&
    (href.startsWith('http') || href.startsWith('#')))

export type { LinkProps }
export { hrefAsPropTypes, linkPropTypes }
export default withLinkWrapper
